import { Link } from "react-router-dom";

export default function HeaderNav() {
  return (
    <header className="sm:px-[40px] nav-header">
      <nav className="navbar">
        <div className="container">
          <div className="nav-main">
            <div className="nav-logo flex justify-start items-start">
              <Link reloadDocument to="/">
                <img
                  src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1714663442/logo-ai-africa_feiiog.svg"
                  alt="logo"
                  className="sm:w-[180px] w-[170px]"
                />
                {/* <img
                  src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1734016115/AI_Africa_Christmas_Logo_-_WHITE_imo8ws.png"
                  alt="logo"
                  className="sm:w-[180px] w-[170px]"
                /> */}
              </Link>
            </div>
            <div className="nav-desktop">
              <ul>
                <li className="nav-desktop-list-item">
                  <p className="text bleft flex items-center">
                    Products{" "}
                    <img
                      className="h-[7px] w-[11px] mt-[5px] ml-[5px]"
                      src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1685093439/expand_more_lmgx4s.png"
                      alt=""
                    />
                  </p>
                  <div className="showcase single">
                    <ul className="showcase-item flex flex-col  gap-[10px]">
                      <li>
                        <a
                          href="https://powerdby.ai/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Powerdby[AI]
                        </a>
                      </li>
                      <li></li>
                      <li className="">
                        <a
                          href="https://dataassistant.ai/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Data Assistant
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-desktop-list-item">
                  <p className="text bleft flex items-center">
                    Learn
                    <img
                      className="h-[7px] w-[11px] mt-[5px] ml-[5px]"
                      src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1685093439/expand_more_lmgx4s.png"
                      alt=""
                    />
                  </p>
                  <div className="showcase single">
                    <ul className="showcase-item flex flex-col  gap-[10px]">
                      <li>
                        <Link to="/corporate-training/ai">
                          Corporate Training
                        </Link>
                      </li>
                      <li></li>
                      <li>
                        <a
                          href="https://aiacademy.africa/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          [AI] Academy Africa
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                {/* <li>
                  <Link reloadDocument to="/solution" className="blog nav-desktop-list-item">
                    <p className="text">Solutions</p>
                  </Link>
                </li> */}
                {/* <li className="nav-desktop-list-item">
                  <p className="text bleft">Solutions</p>
                  <div className="showcase single">
                    <ul className="showcase-item flex flex-col justify-end items-end gap-[10px]">
                      <li>
                        <Link reloadDocument to="/data-assistant/marketing">
                          Digital marketing
                        </Link>
                      </li>
                      <li>
                        <Link reloadDocument to="/data-assistant/ecommerce">
                          E-commerce
                        </Link>
                      </li>
                      <li>
                        <Link reloadDocument to="/data-assistant/fintech">
                          Fintech
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li> */}
                <li className="nav-desktop-list-item">
                  <p className="text bleft flex items-center">
                    Company
                    <img
                      className="h-[7px] w-[11px] mt-[5px] ml-[5px]"
                      src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1685093439/expand_more_lmgx4s.png"
                      alt=""
                    />
                  </p>
                  <div className="showcase single">
                    <ul className="showcase-item flex flex-col  gap-[10px]">
                      <li>
                        <Link reloadDocument to="/about">
                          About us
                        </Link>
                      </li>
                      <li>
                        <Link reloadDocument to="/event">
                          Event
                        </Link>
                      </li>
                      <li>
                        <Link reloadDocument to="/community">
                          Community
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <Link
                    reloadDocument
                    to="/blog"
                    className="blog nav-desktop-list-item"
                  >
                    <p className="text">Blog</p>
                  </Link>
                </li>
                <li>
                  <Link
                    reloadDocument
                    to="/demo"
                    className="blog nav-desktop-list-item border border-[1px] border-[#0073FF] rounded-[8px] flex items-center justify-center p-[8px]"
                    rel="noreferrer"
                  >
                    <p className="">Request Demo</p>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="nav-desktop-clone">
              <div className="clone-items">
                <div className="clone-item">
                  <h3
                    className="clone-title"
                    onClick={(e) => {
                      if (e.target.nextSibling) {
                        e.target.nextSibling.classList.toggle("extend");
                      }
                      if (e.target.parentElement) {
                        e.target.parentElement.nextSibling.classList.toggle(
                          "extend"
                        );
                      }
                    }}
                  >
                    <span>Products</span>
                    <i className="fa-solid fa-angle-down"></i>
                  </h3>
                  <div className="clone-list">
                    <a
                      href="https://powerdby.ai/"
                      target="_blank"
                      rel="noreferrer"
                      className="clone-link"
                    >
                      Powerdby[AI]
                    </a>
                    <a
                      href="https://dataassistant.ai/"
                      target="_blank"
                      rel="noreferrer"
                      className="clone-link"
                    >
                      Data Assistant
                    </a>
                  </div>
                </div>
                <div className="clone-item">
                  <h3
                    className="clone-title"
                    onClick={(e) => {
                      if (e.target.nextSibling) {
                        e.target.nextSibling.classList.toggle("extend");
                      }
                      if (e.target.parentElement) {
                        e.target.parentElement.nextSibling.classList.toggle(
                          "extend"
                        );
                      }
                    }}
                  >
                    <span>Learn</span>
                    <i className="fa-solid fa-angle-down"></i>
                  </h3>
                  <div className="clone-list">
                    <Link
                      reloadDocument
                      to="/corporate-training/ai"
                      className="clone-link"
                    >
                      Corporate Training
                    </Link>
                    <a
                      href="https://aiacademy.africa/"
                      target="_blank"
                      rel="noreferrer"
                      className="clone-link"
                    >
                      [AI] Academy Africa
                    </a>
                  </div>
                </div>
                <div className="clone-item">
                  <h3
                    className="clone-title"
                    onClick={(e) => {
                      if (e.target.nextSibling) {
                        e.target.nextSibling.classList.toggle("extend");
                      }
                      if (e.target.parentElement) {
                        e.target.parentElement.nextSibling.classList.toggle(
                          "extend"
                        );
                      }
                    }}
                  >
                    <span>Company</span>

                    <i className="fa-solid fa-angle-down"></i>
                  </h3>
                  <div className="clone-list">
                    <Link reloadDocument to="/about" className="clone-link">
                      About us
                    </Link>
                    <Link reloadDocument to="/event" className="clone-link">
                      Event
                    </Link>
                    <Link reloadDocument to="/community" className="clone-link">
                      Community
                    </Link>
                  </div>
                </div>
                <div className="clone-item">
                  <Link reloadDocument to="/blog" className="clone-title">
                    <span>Blog</span>
                  </Link>
                </div>
                <div className="clone-item">
                  <Link
                    reloadDocument
                    to="/demo"
                    className="clone-title border border-[1px] border-[#0073FF] rounded-[8px] flex items-center justify-center p-[8px] max-w-[150px]"
                    rel="noreferrer"
                  >
                    <span>Request Demo</span>
                  </Link>
                </div>
              </div>
            </div>
            <button
              className="nav-burger"
              onClick={() => {
                document
                  .querySelector(".nav-desktop-clone")
                  .classList.toggle("pull-down");
              }}
            >
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1684142621/hamburger_menu_ikjzae.svg"
                alt="hamburgerMenu"
              />
            </button>
          </div>
        </div>
      </nav>
      <style jsx="true">{`
        .nav-desktop {
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
        }
        .clone-list {
          border-top: 2px solid #fff;
          border-bottom: 2px solid #fff;
          display: none;
        }
        .extend.clone-list {
          display: block !important;
        }
        .clone-link {
          display: block;
          color: inherit;
          text-decoration: none;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
        .clone-title {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          font-size: 1.2rem !important;
          margin-bottom: 1rem;
        }
        .showcase {
          position: absolute;
          right: 0;
          display: grid;
          border-radius: 20px;
          visibility: hidden;
          // transform: translateX(-40%);
          padding: 20px;
          background-color: #0d0d0d;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          flex-shrink: 0;
          overflow: hidden;
          z-index: 100;
        }
        .showcase.triple {
          grid-template-columns: repeat(2, 250px);
        }
        .showcase.single {
          grid-template-columns: 160px;
        }
        .showcase.single.upcoming {
          grid-template-columns: 300px;
        }
        .showcase.single.upcoming li {
          text-align: center;
        }
        .showcase .showcase-item {
          display: flex;
          flex-direction: column;
          justify-content: end;
          color: #fff;
          list-style: none;
          height: 100%;
          align-items: end;
        }
        .showcase .showcase-item img {
          width: 100%;
          object-fit: cover;
        }
        .nav-header {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          background-color: #000000;
          z-index: 100000;
        }
        .nav-main {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .container {
          padding-right: 20px;
          padding-left: 20px;
        }
        .nav-burger {
          display: none;
          background-color: transparent;
          outline: 0px;
          border: 0px;
          cursor: pointer;
        }
        .nav-desktop {
          display: none;
        }
        .nav-desktop > ul {
          list-style: none;
          display: flex;
          align-items: center;
          color: #fff;
        }
        .nav-desktop-list-item {
          position: relative;
          margin-left: 2rem;
        }
        .nav-desktop ul li .text {
          cursor: pointer;
          font-size: 1.1rem;
          padding: 20px 0px;
        }
        .bleft {
          padding-right: 10px;
          border-right: 1px solid #000000;
        }
        // .nav-desktop ul li .text:hover {
        //   border-right: 1px solid white;
        // }
        .login-btn {
          padding: 10px 4rem;
        }
        button.academy {
          background-color: #ff580c;
          margin-top: 1rem;
          margin-bottom: 1rem;
          padding: 10px 4rem;
        }
        .cta-text {
          font-weight: 600;
          font-size: 1.2rem;
          margin-top: 1rem;
        }
        .nav-desktop ul li.title {
          font-weight: 700;
          margin-bottom: 1rem;
          font-size: 1.2rem;
        }
        .nav-desktop ul li a {
          color: inherit;
          text-decoration: none;
          display: block;
          font-size: 1.1rem;
        }
        .nav-desktop ul li .blog {
          margin-bottom: 0px;
        }
        .nav-desktop ul li .blog:hover {
          text-decoration: none;
        }
        .nav-desktop ul li a:hover {
          text-decoration: underline;
        }
        .nav-desktop-clone {
          position: absolute;
          top: 100%;
          display: none;
          height: auto;
          padding: 1rem;
          left: 0;
          right: 0;
          transition: transform 400ms ease 0s;
          display: none;
          background-color: #000000;
          color: #fff;
          z-index: -1;
        }
        @media (max-width: 900px) {
          .pull-down {
            display: block;
          }
          .nav-burger {
            display: block;
            padding-top: 2rem;
            padding-bottom: 2rem;
          }
        }
        @media (min-width: 901px) {
          .nav-desktop {
            display: block;
          }
          .nav-desktop > ul li:hover .showcase {
            visibility: visible;
          }
        }
      `}</style>
    </header>
  );
}
